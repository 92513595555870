import React, { forwardRef, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { Dialog } from 'components/dialog';
import { resolveDialogContent } from 'components/dialog/utilities';
import { DialogContent } from 'components/dialog-content';
import { HeaderSecond } from 'components/header-second';
import { Overlay } from 'components/overlay';
import { Paragraph } from 'components/paragraph';
import { RecaptchaWrapper } from 'components/recaptcha-wrapper';
import { FORM_STATUSES } from 'constants/form-statuses';
import { CONSTANTS } from 'constants/styles/constants';
import { string } from 'prop-types';

import { RecruitForm } from '../../../components/recruit-modal-form/components/recruit-form';
import { customDialogContent } from '../../../components/recruit-modal-form/constants';

const SContainer = styled.div`
    position: relative;
`;

const SSubContainer = styled(Container)`
    display: flex;
    gap: 4.25rem;
    padding-right: 0;
    align-items: center;

    ${CONSTANTS.MEDIA.max900`
        flex-direction: column;
        padding-right: var(--section-clamp);
    `}

    ${CONSTANTS.MEDIA.max600`
        padding: 0;
    `}
`;

const SLeftContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    color: var(--white-color);
    gap: 1.5rem;

    ${CONSTANTS.MEDIA.max1200`
        flex: 0.7;
    `}

    ${CONSTANTS.MEDIA.max600`
        padding: 0 var(--section-clamp);
    `}
`;

const SParagraph = styled(Paragraph)`
    font-size: 1.375rem;
`;

const SRightContainer = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    margin-right: 2.75rem;

    > :first-child {
        max-height: unset;
        background: var(--white-color);
        border-radius: 1.5rem;

        > :first-child {
            padding-top: 1.5rem;
        }
    }

    ${CONSTANTS.MEDIA.max1440`
        margin-right: 0;

        > : first-child { 
            padding-right: 4.5rem;
            border-radius: 1.5rem 0 0 1.5rem;
        }
    `}

    ${CONSTANTS.MEDIA.max1280`
        > : first-child { 
            padding-right: 3rem;
        }
    `}

    ${CONSTANTS.MEDIA.max1024`
        > : first-child { 
            padding: 1.625rem 0;
        }
    `}

    ${CONSTANTS.MEDIA.max900`
        > : first-child { 
            padding-right: 1.625rem;
            border-radius: 1.5rem;
        }
    `}

    ${CONSTANTS.MEDIA.max600`
        > : first-child {
            border-radius: 0;
            padding: 3.125rem 1.25rem;
            
            > : first-child {
                padding: 0;
            }
        }
    `}
`;

const SScrollOffset = styled.div`
    height: 7.5rem;
    top: -7.5rem;
    position: absolute;
    pointer-events: none;
`;

const SRadialGradient = styled.div`
    width: 52.25rem;
    height: 52.25rem;
    bottom: 10%;
    left: 10%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: ${CONSTANTS.ZINDEX.ZERO};
    background-image: radial-gradient(
        at 50% 50%,
        hsla(187, 57%, 53%, 0.25) 0,
        transparent 70%
    );

    ${CONSTANTS.MEDIA.max1024`
        width: 47rem;
        height: 47rem;
        bottom: 20%
    `}

    ${CONSTANTS.MEDIA.max900`
        width: 100vw;
        left: 50%;
        bottom: 5%;
    `}

    ${CONSTANTS.MEDIA.max600`
        display: none;
    `}
`;

export const RecruitFormSection = forwardRef(({ title }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formStatus, setFormStatus] = useState(FORM_STATUSES.IDLE);

    const dialogRef = useRef();

    const onFormSubmit = useCallback((status) => {
        setFormStatus(status);

        if (status !== FORM_STATUSES.IDLE) {
            dialogRef?.current?.showModal();
        }
    }, []);

    const onClose = useCallback(() => {
        if (formStatus === FORM_STATUSES.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR) {
            window.location.reload();
            return;
        }

        dialogRef?.current?.close();
        setFormStatus(FORM_STATUSES.IDLE);
    }, [formStatus]);

    return (
        <SContainer>
            <SScrollOffset ref={ref} />
            <SSubContainer>
                <SLeftContainer>
                    <HeaderSecond>
                        <FormattedMessage id="view-job-offer.recruit-form.creatingWorld" />
                    </HeaderSecond>
                    <SParagraph>
                        <FormattedMessage id="view-job-offer.recruit-form.turningDataIntoProfit" />
                    </SParagraph>
                    <SParagraph>
                        <FormattedMessage id="view-job-offer.recruit-form.excitingJob" />
                    </SParagraph>
                </SLeftContainer>
                <SRightContainer>
                    <RecaptchaWrapper>
                        <RecruitForm
                            setIsLoading={setIsLoading}
                            vacancy={title}
                            isScrollDisabled
                            onFormSubmit={onFormSubmit}
                        />
                    </RecaptchaWrapper>
                    <SRadialGradient />
                </SRightContainer>
            </SSubContainer>
            <Overlay show={isLoading} />
            <Dialog ref={dialogRef}>
                <DialogContent
                    {...resolveDialogContent({
                        formStatus,
                        customDialogContent,
                    })}
                    firstParagraphMargin
                    onClose={onClose}
                />
            </Dialog>
        </SContainer>
    );
});

RecruitFormSection.propTypes = {
    title: string.isRequired,
};

RecruitFormSection.defaultProps = {
    title: '',
};

RecruitFormSection.displayName = 'RecruitFormSection';
